<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">
            {{ readOnly ? $t("view_customer") : $t("edit") }}
          </span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container class="pb-0 my-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.name"
                  dense
                  :label="$t('name_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.email"
                  dense
                  :label="$t('email_*')"
                  :disabled="readOnly"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemCustomer.device_type"
                  dense
                  :items="deviceTypeLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('device_type_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-if="isOsTypeIos"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.apple_email"
                  dense
                  :label="$t('apple_email_*')"
                  :disabled="readOnly"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col v-if="authUser.isAdmin">
                <v-select
                  v-model="oemCustomer.product"
                  dense
                  disabled
                  :items="['oem', 'fleet']"
                  :label="$t('select_product_*')"
                  :rules="[rules.required]"
                  @change="
                    () => {
                      // delete oemCustomer.tenant_id;
                      // delete oemCustomer.user_type;
                    }
                  "
                />
              </v-col>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="oemCustomer.tenant_id"
                  dense
                  disabled
                  :loading="tenantLoading"
                  :items="tenantsByProduct"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="[rules.required]"
                />
                <!-- <v-select
                  v-model="oemCustomer.tenant_id"
                  dense
                  :items="clientsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  disabled
                  :rules="[rules.required]"
                /> -->
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.date_of_birth"
                  dense
                  :label="$t('date_of_birth_*')"
                  type="date"
                  :max="getCurrentDate()"
                  :disabled="readOnly"
                  :rules="[rules.required, validateDateOfBirth]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.address"
                  dense
                  :disabled="readOnly"
                  :label="$t('address')"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemCustomer.gender"
                  dense
                  :items="genderLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('gender_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.cnic"
                  dense
                  :label="$t('cnic_*')"
                  type="number"
                  :disabled="readOnly"
                  :rules="[
                    rules.required,
                    rules.min_length(13),
                    rules.max_length(13),
                  ]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemCustomer.country_code"
                  dense
                  :items="countryCodeLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('country_code_*')"
                  disabled
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemCustomer.phone_number"
                  dense
                  type="number"
                  :label="$t('phone_number_*')"
                  :disabled="readOnly"
                  :rules="[
                    rules.required,
                    rules.startFrom(3),
                    rules.min_length(10),
                    rules.max_length(10),
                  ]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemCustomer.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemCustomer.user_type"
                  dense
                  :items="userTypeLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('user_type_*')"
                  disabled
                  :rules="[rules.required]"
                  @change="onChangeUsertype"
                />
              </v-col>

              <v-col
                v-if="oemCustomer.user_type == 'business'"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-if="readOnly || authUser.user_nature == 'fleet'"
                  dense
                  disabled
                  :value="oemCustomer.oem_b2b_name"
                  :label="$t('business')"
                />
                <v-select
                  v-else
                  v-model="oemCustomer.oem_b2b_id"
                  dense
                  disabled
                  :items="oemB2bList"
                  item-value="id"
                  item-text="business_name"
                  clearable
                  :label="$t('business')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-checkbox
                  v-if="!readOnly"
                  v-model="oemCustomer.test_customer"
                  dense
                  disabled
                  hide-details
                  :ripple="false"
                  :label="
                    oemCustomer.user_type == 'business'
                      ? $t('test_driver')
                      : $t('test_customers')
                  "
                  :true-value="1"
                  :false-value="0"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0 mt-0">
          <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            v-if="!readOnly"
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(oemCustomer)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    oemCustomer: {
      type: Object,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      oemCustomerMakes: [],
      stream: [],
      plugin: [],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],

      deviceTypeLists: [
        {
          id: "android",
          name: "Android",
        },
        {
          id: "ios",
          name: "IOS",
        },
      ],
      genderLists: [
        {
          id: "male",
          name: "Male",
        },
        {
          id: "female",
          name: "Female",
        },
      ],
      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      oemB2bList: "oemBusiness/getOEMB2BList",
    }),
    tenantsByProduct() {
      if (this.oemCustomer?.product) {
        if (this.oemCustomer.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    userTypeLists() {
      return [
        {
          id: "individual",
          name: "Individual",
        },
        ...(this.oemCustomer.product == "fleet"
          ? []
          : [
              {
                id: "business",
                name: "Business",
              },
            ]),
        {
          id: "internal",
          name: "Internal",
        },
      ];
    },
    isOsTypeIos() {
      return this.oemCustomer.device_type == "ios";
    },
  },

  watch: {
    "oemCustomer.device_type": {
      handler: async function (value) {
        if (value === "android") {
          this.oemCustomer.apple_email = null;
        }
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("oemBusiness/list");
  },
  methods: {
    async updateItem(oemCustomer) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("oemCustomers/update", oemCustomer)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    onChangeUsertype() {
      this.oemCustomer.oem_b2b_id = undefined;
    },
  },
};
</script>
